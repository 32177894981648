var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('text-field-title',{attrs:{"title":"위치","subTitle":"특정 위치에 거주하거나 방문한 사람"}}),_c('v-autocomplete',{attrs:{"items":_vm.locations,"loading":_vm.isLoading,"search-input":_vm.searchString,"item-text":"place_name","hide-no-data":"","item-value":"xy","clearable":"","prepend-inner-icon":"mdi-magnify","outlined":"","return-object":"","auto-select-first":""},on:{"update:searchInput":function($event){_vm.searchString=$event},"update:search-input":function($event){_vm.searchString=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getLocations()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item.place_name),staticClass:"px-1 py-5",staticStyle:{"height":"60px"},attrs:{"input-value":data.selected,"disabled":data.disabled,"label":"","color":"info"},on:{"click:close":function($event){return data.parent.selectItem(data.item.place_name)}}},'v-chip',data.attrs,false),[_c('h3',{staticClass:"px-3"},[_vm._v(_vm._s(data.item.place_name))])])]}}]),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_vm._l((_vm.campaign.rs3.locations),function(location,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-3 ma-1",attrs:{"dark":""}},[_c('p',{staticClass:"title"},[_vm._v("주소: "+_vm._s(location.address))]),_c('p',{staticClass:"title"},[_vm._v(" 위도: "+_vm._s(location.lat.toString().slice(0, 14))+" ")]),_c('p',{staticClass:"title"},[_vm._v(" 경도: "+_vm._s(location.lng.toString().slice(0, 14))+" ")]),_c('p',{staticClass:"title"},[_vm._v(" 반경: "+_vm._s(Number(location.radius).toLocaleString())+"m ")]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","opacity":"0.9"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function () {
                          _vm.setCenter(location.lat, location.lng);
                          _vm.zoomIn(8);
                        }}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-crosshairs-gps")])],1)]}}],null,true)},[_c('span',[_vm._v("위치이동")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function () {
                          _vm.campaign.rs3.locations.splice(index, 1);
                          _vm.setMarkers(null);
                          _vm.removeCircles(index);
                        }}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("삭제")])])],1):_vm._e()],1)],1)]}}],null,true)})})],2),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v("마우스를 더블클릭 후 움직여 위치 반경을 지정해주세요.")]),_c('div',{staticStyle:{"width":"100%","height":"600px"},attrs:{"id":"kakaoMap"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }